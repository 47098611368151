import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import _ from 'lodash';
import Common from '../../constants/common';
import * as apiAction from '../../actions/apiAction';
import * as crudAction from '../../actions/crudAction';
import * as flashMessage  from '../../actions/flashMessage';
import renderText from '../common/form/renderText';
import FlashMessage from "../common/flash/message.component";
import axios from "axios";
import AppConstant from "../../constants/app";
import sha256 from "../../constants/sha256";
import {clearToken} from "../../utils/actionUtil";

class AccountList extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
    }

    componentWillMount() {
        this.props.actions.fetchById(Common.ACCOUNT, 0);
        this.props.actions.removeFlashMessage();
    }

    componentDidMount() {

        document.title = "Mein digiSeal Konto – digiSeal Portal by secrypt GmbH"

        $('input[name="username"]').off('click').on('click', function(){
            $('.edit-username-btn').click();
        });
        $('input[name="password"]').off('click').on('click', function(){
            $('.edit-password-btn').click();
        });
        $('input[name="mailAddress"]').off('click').on('click', function(){
            $('.edit-email-btn').click();
        });

        $('input[name="salutation"]').off('click').on('click', function(){
            if ($(this).is(':checked')) {

            }
        });

        $('.edit-username-btn').off('click').on('click', function (e) {

            var oldUsername = $('input[name="username"]').val();
            // to prevent html in username to be executed
            var escape = document.createElement('textarea');
            escape.textContent = oldUsername;
            oldUsername = escape.innerHTML;

            setTimeout(function() {
                const togglePasswordConfirm = document.querySelector('#togglePasswordConfirm');
                const passwordConfirm = document.getElementsByName("password-value");

                togglePasswordConfirm.addEventListener('click', function (e) {
                    const type = passwordConfirm[0].getAttribute('type') === 'password' ? 'text' : 'password';
                    passwordConfirm[0].setAttribute('type', type);
                    this.classList.toggle('dash');
                });
            }, 1000);

            $.confirm({
                title: false,
                content: '<div class="modal-editor">'+
                    '<div class="data-container"><label>Alter Benutzername:</label><div class="old-entry">'+oldUsername+'</div><span class="input-info-output"></span></div>' +
                    '<div class="data-container"><label>Neuer Benutzername:</label><input type="text" name="new-username-value" class="new-username-value form-control" /><span class="input-info-output"></span></div>' +
                    '<div class="data-container"><label>Mit Passwort bestätigen:</label><input type="password" name="password-value" class="password-value form-control" /><span class="input-info-output"></span><i class="eye togglePasswordConfirm positioned" id="togglePasswordConfirm"></i></div>' +
                    '</div>',
                buttons: {
                    confirm: {
                        text : 'Änderungen übernehmen',
                        btnClass: 'modal-editor-btn btn-primary btn',
                        action: function () {
                            var data = {};
                            data.newUsername = $('.new-username-value').val();
                            data.password = $('.password-value').val();
                            var pwHash = $('.system-user-name').data('pw');
                            var pwInputHash = sha256(data.password).toUpperCase();

                            if (!data.newUsername) {
                                $('input[name="new-username-value"]').next('.input-info-output').addClass('error').html('Bitte geben Sie den neuen Benutzernamen ein.');
                                return false;
                            }

                            if (!data.password) {
                                $('input[name="password-value"]').next('.input-info-output').addClass('error').html('Bitte geben Sie das Passwort ein.');
                                return false;
                            }

                            if (pwInputHash !== pwHash) {
                                $('input[name="password-value"]').next('.input-info-output').addClass('error').html('Das Passwort ist falsch.');
                                return false;
                            }

                            axios.post(AppConstant.API_URL + 'account-username-update', {data})
                                .then(res => {
                                    $.toast({
                                        heading: 'Änderungen gespeichert',
                                        text: 'Ihre Änderungen wurden erfolgreich gespeichert. Bitte prüfen Sie Ihren E-Mail Posteingang.',
                                        showHideTransition: 'slide',
                                        position: 'top-right',
                                        icon: 'success',
                                        allowToastClose: true,
                                        hideAfter: 3000,
                                        bgColor : '#6cbf00'
                                    })
                                });
                        }
                    },
                    cancel: {
                        text : 'Abbrechen',
                        btnClass : 'cancel-btn',
                        action: function () {}
                    },
                }
            });
            e.preventDefault();
        })

        $('.edit-password-btn').off('click').on('click', function (e) {

            $.confirm({
                title: false,
                content: '<div class="modal-editor">'+
                    '<div class="data-container"><label>Altes Passwort:</label><input type="password" name="old-password-value" class="old-password-value form-control" /><span class="input-info-output"></span><i class="eye togglePassword positioned" id="togglePasswordOld"></i></div>' +
                    '<div class="data-container"><label>Neues Passwort:</label><input type="password" id="new-password-value" name="new-password-value" class="new-password-value form-control" /><span class="input-info-output"></span></div>' +
                    '<div class="data-container"><label>Neues Passwort wiederholen:</label><input type="password" name="new-password-value-repeat" class="new-password-value-repeat form-control" id="new-password-value-repeat" /><span class="input-info-output"></span><i class="eye togglePassword positioned" id="togglePassword"></i></div>' +
                    '</div>',
                buttons: {
                    confirm: {
                        text : 'Änderungen übernehmen',
                        btnClass: 'modal-editor-btn btn-primary btn',
                        action: function () {
                            var data = {};
                            data.oldPassword = $('.old-password-value').val();
                            data.newPassword = $('.new-password-value').val();
                            data.newPasswordRepeat = $('.new-password-value-repeat').val();

                            var pwHash = $('.system-user-name').data('pw');
                            var pwInputHash = sha256(data.oldPassword).toUpperCase();

                            $('.input-info-output').html('');

                            if (!data.oldPassword) {
                                $('.old-password-value').next('.input-info-output').addClass('error').html('Bitte geben Sie das aktuelle Passwort ein.');
                                return false;
                            }
                            if (pwInputHash !== pwHash) {
                                $('.old-password-value').next('.input-info-output').addClass('error').html('Das aktuelle Passwort ist falsch.');
                                return false;
                            }
                            if (!data.newPassword) {
                                $('.new-password-value').nextAll('.input-info-output').addClass('error').html('Bitte geben Sie das neue Passwort ein.');
                                return false;
                            }
                            if (!data.newPasswordRepeat) {
                                $('.new-password-value-repeat').next('.input-info-output').addClass('error').html('Bitte geben Sie das neue Passwort noch einmal ein.');
                                return false;
                            }
                            if (data.newPassword !== data.newPasswordRepeat){
                                $('.new-password-value-repeat').next('.input-info-output').addClass('error').html('Passwort und Passwort-Wiederholung sind nicht gleich.');
                                return false;
                            }

                            axios.post(AppConstant.API_URL + 'account-password-update', {data})
                                .then(res => {
                                    $.toast({
                                        heading: 'Änderungen gespeichert',
                                        text: 'Ihre Änderungen wurden erfolgreich gespeichert. Bitte prüfen Sie Ihren E-Mail Posteingang.',
                                        showHideTransition: 'slide',
                                        position: 'top-right',
                                        icon: 'success',
                                        allowToastClose: true,
                                        hideAfter: 3000,
                                        bgColor : '#6cbf00'
                                    })
                                });
                        }
                    },
                    cancel: {
                        text : 'Abbrechen',
                        btnClass : 'cancel-btn',
                        action: function () {}
                    },
                }
            });

            setTimeout(function() {
                $('#new-password-value').strength({
                    strengthClass: 'strength',
                    strengthMeterClass: 'strength_meter',
                    strengthButtonClass: 'button_strength',
                    strengthButtonText: '',
                    strengthButtonTextToggle: ''
                });
                const password = document.getElementsByName("new-password-value-repeat");
                const passwordOld = document.getElementsByName("old-password-value");
                const togglePassword = document.querySelector('#togglePassword');
                const togglePasswordOld = document.querySelector('#togglePasswordOld');

                togglePassword.addEventListener('click', function (e) {
                    const type = password[0].getAttribute('type') === 'password' ? 'text' : 'password';
                    password[0].setAttribute('type', type);
                    this.classList.toggle('dash');
                });
                togglePasswordOld.addEventListener('click', function (e) {
                    const type = passwordOld[0].getAttribute('type') === 'password' ? 'text' : 'password';
                    passwordOld[0].setAttribute('type', type);
                    this.classList.toggle('dash');
                });
            }, 1000);

            e.preventDefault();
        })

        $('.edit-email-btn').off('click').on('click', function (e) {
            var oldEmail = $('input[name="mailAddress"]').val();
            setTimeout(function() {
                const togglePasswordConfirmEmail = document.querySelector('#togglePasswordConfirmEmail');
                const passwordConfirmEmail = document.getElementsByName("password-value");

                togglePasswordConfirmEmail.addEventListener('click', function (e) {
                    const type = passwordConfirmEmail[0].getAttribute('type') === 'password' ? 'text' : 'password';
                    passwordConfirmEmail[0].setAttribute('type', type);
                    this.classList.toggle('dash');
                });
            }, 1000);

            $.confirm({
                title: false,
                content: '<div class="modal-editor">'+
                    '<label>Alte E-Mail:</label><div class="old-entry">'+oldEmail+'</div>' +
                    '<div class="data-container"><label>Neue E-Mail:</label><input type="text" name="new-email-value" class="new-email-value form-control" /><span class="input-info-output"></span></div>' +
                    '<div class="data-container"><label>Mit Passwort bestätigen:</label><input type="password" name="password-value" class="password-value form-control" /><span class="input-info-output"></span><i class="eye togglePasswordConfirmEmail positioned" id="togglePasswordConfirmEmail"></i></div>' +
                    '</div>',
                buttons: {
                    confirm: {
                        text : 'Änderungen übernehmen',
                        btnClass: 'modal-editor-btn btn-primary btn',
                        action: function () {
                            var data = {};
                            data.newMail = $('.new-email-value').val();
                            data.password = $('.password-value').val();
                            var pwHash = $('.system-user-name').data('pw');
                            var pwInputHash = sha256(data.password).toUpperCase();

                            $('.input-info-output').html('');

                            if (!data.newMail) {
                                $('.new-email-value').next('.input-info-output').addClass('error').html('Bitte geben Sie die neue E-Mail ein.');
                                return false;
                            }
                            if (!data.password) {
                                $('.password-value').next('.input-info-output').addClass('error').html('Bitte geben Sie Ihr Passwort ein.');
                                return false;
                            }

                            if (pwInputHash !== pwHash) {
                                $('.password-value').next('.input-info-output').addClass('error').html('Das aktuelle Passwort ist falsch.');
                                return false;
                            }

                            axios.post(AppConstant.API_URL + 'account-email-update', {data})
                                .then(res => {
                                    $.toast({
                                        heading: 'Änderungen gespeichert',
                                        text: 'Ihre Änderungen wurden erfolgreich gespeichert. Bitte prüfen Sie Ihren E-Mail Posteingang.',
                                        showHideTransition: 'slide',
                                        position: 'top-right',
                                        icon: 'success',
                                        allowToastClose: true,
                                        hideAfter: 3000,
                                        bgColor : '#6cbf00'
                                    })
                                });
                        }
                    },
                    cancel: {
                        text : 'Abbrechen',
                        btnClass : 'cancel-btn',
                        action: function () {}
                    },
                }
            });
            e.preventDefault();
        })

        $('.close-account-btn').off('click').on('click', function(e){
            $.confirm({
                title: 'digiSeal Konto schließen',
                btnClass : 'cancel-btn',
                content: 'Möchten Sie Ihre digiSeal Konto wirklich schließen?',
                buttons: {
                    ok: {
                        text : 'Konto schließen',
                        btnClass : 'btn modal-editor-btn btn-primary',
                        action: function () {
                            axios.post(AppConstant.API_URL + 'account-close', {})
                            .then((response) => {
                                console.log(response);
                                if(response.data.code === "ok") {
                                    clearToken();
                                    window.location.href = AppConstant.ROOT_URL;
                                } else {
                                    $.toast({
                                        heading: 'Konto nicht geschlossen',
                                        text: 'Es ist ein Fehler aufgetreten. Ihr digiSeal Konto konnte nicht geschlossen werden.',
                                        showHideTransition: 'slide',
                                        position: 'top-right',
                                        icon: 'error',
                                        allowToastClose: true,
                                        hideAfter: 3000,
                                        bgColor : '#c01818'
                                    })
                                }
                            });
                        }
                    },
                    abbrechen: {
                        text : 'Abbrechen',
                        btnClass : 'cancel-btn',
                        action: function () {}
                    },
                }
            });
            e.preventDefault();
            return false;
        })
    }

    componentWillUnmount() {
        this.props.actions.clearSelectedItem(Common.ACCOUNT);
        this.props.actions.clearList(Common.ACCOUNT);
        this.props.actions.apiClearState();
        this.props.actions.removeFlashMessage();
    }

    onHandleSubmit(formProps) {
        let givenName = $('input[name="givenName"]');
        let familyName = $('input[name="familyName"]');
        let street = $('input[name="street"]');
        let zipCode = $('input[name="zipCode"]');
        let city = $('input[name="city"]');
        let mail = $('input[name="mail"]');

        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        $('body').find('.help-block').html('');
        $('body').find('.error').removeClass('error');

        if (givenName.val() == '') {
            givenName.next('.help-block').addClass('error').html('Bitte geben Sie einen Vornamen ein.');
        }

        if (familyName.val() == '') {
            familyName.next('.help-block').addClass('error').html('Bitte geben Sie einen Nachnamen ein.');
        }

        if (street.val() == '') {
            street.next('.help-block').addClass('error').html('Bitte geben Sie eine Straße ein.');
        }

        if (zipCode.val() == '') {
            zipCode.next('.help-block').addClass('error').html('Bitte geben Sie eine Postleitzahl ein.');
        }

        if (city.val() == '') {
            city.next('.help-block').addClass('error').html('Bitte geben Sie einen Ort ein.');
        }
        if (mail.val() == '' || !re.test(mail.val())) {
            mail.next('.help-block').addClass('error').html('Bitte geben Sie Ihre E-Mail-Adresse ein.');
        }

        if ($('.error').length > 0) {
            return false;
        }

        this.props.actions.submitForm(Common.ACCOUNT, formProps, this.props.params.id);
    }

    handleChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        this.props.actions.updateSelectedItem(Common.ACCOUNT, key, value);
    }

    render() {

        const {handleSubmit, submitting} = this.props;
        let message = this.props.message;

        return (
            <div className="col-12 my-account-container">

                <p className="col-12 border-all">
                    <div className="form-horizontal">
                        <label className="col-xs-12 control-label">Benutzername:</label>
                        <div className="col-xs-8">
                            <input type="text" name="username" className="form-control" value={this.props.selectedItem.account.username} />
                        </div>
                        <div className="col-xs-4 button">
                            <button type="submit" className="btn btn-primary edit-username-btn">Bearbeiten</button>
                        </div>
                    </div>


                    <div className="form-horizontal">
                        <label className="col-xs-12 control-label">Passwort:</label>
                        <div className="col-xs-8">
                            <input type="password" name="password" className="form-control" value="*****" />
                        </div>
                        <div className="col-xs-4 button">
                            <button type="submit" className="btn btn-primary edit-password-btn">Bearbeiten</button>
                        </div>
                    </div>

                    <div className="form-horizontal">
                        <label className="col-xs-12 control-label">E-Mail:</label>
                        <div className="col-xs-8">
                            <input type="text" name="mailAddress" className="form-control" value={this.props.selectedItem.account.mailAddress} />
                        </div>
                        <div className="col-xs-4 button">
                            <button type="submit" className="btn btn-primary edit-email-btn">Bearbeiten</button>
                        </div>
                    </div>

                    <p className=""></p>
                </p>

                <p className="col-12 border-all personal-data-container">
                        <form className="form-horizontal" onSubmit={handleSubmit(this.onHandleSubmit)}>

                            <div className="box-body margin-top--10px">

                                <div className="row">

                                    <div className="col-xs-6">
                                        <Field
                                            name="givenName"
                                            component={renderText}
                                            label="Vorname"
                                            type="text"
                                            data={this.props.selectedItem.account.givenName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <Field
                                            name="familyName"
                                            component={renderText}
                                            label="Nachname"
                                            type="text"
                                            data={this.props.selectedItem.account.familyName}
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <div className="col-xs-6">
                                        <Field
                                            name="organization"
                                            component={renderText}
                                            label="Unternehmen"
                                            type="text"
                                            data={this.props.selectedItem.account.organization}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <Field
                                            name="organizationUnit"
                                            component={renderText}
                                            label="Abteilung"
                                            type="text"
                                            data={this.props.selectedItem.account.organizationUnit}
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <div className="col-xs-10">
                                        <Field
                                            name="street"
                                            component={renderText}
                                            type="text"
                                            data={this.props.selectedItem.account.street}
                                            label="Straße"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-xs-2">
                                        <Field
                                            name="streetNumber"
                                            component={renderText}
                                            type="text"
                                            data={this.props.selectedItem.account.streetNumber}
                                            label="Nr."
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <div className="col-xs-2">
                                        <Field
                                            name="zipCode"
                                            component={renderText}
                                            type="text"
                                            data={this.props.selectedItem.account.zipCode}
                                            label="PLZ"
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <div className="col-xs-6">
                                        <Field
                                            name="city"
                                            component={renderText}
                                            type="text"
                                            data={this.props.selectedItem.account.city}
                                            label="Stadt"
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <div className="col-xs-4">
                                        <Field
                                            name="country"
                                            component={renderText}
                                            type="text"
                                            data={this.props.selectedItem.account.country}
                                            label="Land"
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="box-footer">
                                <div className="col-xs-12">
                                    <div className="form-group button">
                                        <button type="submit"
                                                className="btn btn-primary float-right"
                                                disabled={submitting}>{(this.props.params.id) ? 'Aktualisieren' : 'Änderung übernehmen'}</button>
                                    </div>
                                </div>
                            </div>
                            <FlashMessage message={message} />
                        </form>
                </p>

                <p className="col-12 border-all close-account-container">
                    <h4 className='subheadline col-xs-12'>Konto schließen</h4>
                    <p className="col-xs-12">
                    Sie können Ihr digiSeal Konto schließen. <br />
                    Die Kontoschließung hat folgende Auswirkungen: <br />
                    <ul className='col-xs-12'>
                        <li>Ihre digiSeal Punke Zugangsschlüssel werden ungültig.</li>
                        <li>digiSeal Punkte aus Ihrem digiSeal Punkte Kontingent können nicht mehr genutzt werden.</li>
                        <li>Ihre digiSeal Konto Zugangsdaten (Benutzername und Passwort) werden ungültig und Sie können sich nicht mehr in Ihr Konto einloggen.</li>
                        <li>Zur Schließung Ihres Kontos erhalten Sie ein letztes digiSeal Punkte Verbrauchsprotokoll.</li>
                    </ul>
                    </p>
                    <div className="col-xs-12">
                        <div className="form-group button">
                            <button type="submit" className="btn btn-primary float-right close-account-btn">
                                Konto schließen
                            </button>
                        </div>
                    </div>
                </p>
            </div>
        );
    }
}

AccountList.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        selectedItem: state.crud.selectedItem,
        initialValues: state.crud.selectedItem.transactionaccount,
        apiState: state.api,
        message: state.flash.message
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, crudAction, apiAction, flashMessage), dispatch)
    }
}


/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'AccountForm', // ←A Unique identifier for this form
    enableReinitialize: true,
    keepDirtyOnReinitialize: false
})(AccountList))
