import React, {Component} from 'react';
import PropTypes from 'prop-types';

import axiosGetStatistics from "./axiosGetStatistics";

var StatisticsDay = (props) => {
	const { year, month, data } = props;
	var monthS = ("00" + month).slice(-2);
	var dayS = ("00" + data.day).slice(-2);
	const label = (props.type == 'user') ? (data.user) : (year + '-' + monthS + '-' + dayS);

	return (
		<tr className="child-child">
			<td className="username" title={label}>{label}</td>
			<td>{data.ID_QES}</td>
			<td>{data.QES}</td>
			<td>{data.ADV}</td>
			<td>{data.BAS}</td>
			<td>{data.Q_SEAL}</td>
			<td>{data.QTSP}</td>
			<td>{data.totalPoints}</td>
		</tr>
	);
};

class StatisticsMonth extends Component
{
	constructor(props) {
		super(props);

		this.state = {
			days: undefined,
		};
    }

	/* checks if the 'open' object points to this object */
	isThisOpen (year, month, open) {
		return open && open.year == year && open.month == month;
	}

	handleClick () {
		const { year, month, monthOpen, onMonthOpen } = this.props;

		const isOpen = this.isThisOpen(year, month, monthOpen);
		onMonthOpen (isOpen ? undefined : { year, month });
	}

	componentDidUpdate(prevProps) {
		const { year, month, type, monthOpen } = this.props;

		const isOpen = this.isThisOpen(year, month, monthOpen);
		const prevIsOpen = this.isThisOpen(prevProps.year, prevProps.month, prevProps.monthOpen);

		if (isOpen != prevIsOpen) {
			if (!isOpen) {
				this.setState({ days: undefined });
			}
			else {
				axiosGetStatistics.getMonth (year, month, type, (data) => {
					if (this.isThisOpen (this.props.year, this.props.month, this.props.monthOpen)) {
						if (type == 'day')
							this.setState({ days: data && data.days });
						else
							this.setState({ days: data && data.users });
					}
				});
			}
		}
	}

	render () {
		const { days } = this.state;
		const { data, year, month, type, monthOpen } = this.props;
		let monthS = ("00" + month).slice(-2);

		const isOpen = monthOpen && monthOpen.year == year && monthOpen.month == month;

		var classNames = 'child has-children ' + (isOpen ? 'open' : 'closed');

		return (
			<>
				<tr className={classNames} onClick={() => this.handleClick()}>
					<td>{year}-{monthS}<span className="break">Gesamt</span></td>
					<td>{data.ID_QES}</td>
					<td>{data.QES}</td>
					<td>{data.ADV}</td>
					<td>{data.BAS}</td>
					<td>{data.Q_SEAL}</td>
					<td>{data.QTSP}</td>
					<td>{data.totalPoints}</td>
				</tr>
				{days && days.map( (day,index) =>
					<StatisticsDay key={year + month + index} year={year} month={month} data={day} type={type} />
				)}
			</>
		);
	}
};

class StatisticsYear extends Component
{
	constructor(props) {
		super(props);

		this.state = { isOpen: false };
    }

	handleClick () {
		/* if one of our childs is open, we must close it here */
		if (this.state.isOpen && this.props.monthOpen && this.props.monthOpen.year == this.props.year) {
			this.props.onOpen(undefined);
		}

		this.setState({ isOpen : !this.state.isOpen });
	}

	render () {
		const { data, type, monthOpen, onMonthOpen } = this.props;

		var classNames = 'parent has-children ' + (this.state.isOpen ? 'open' : 'closed');

		return (
			<>
				<tr className={classNames} onClick={() => this.handleClick()}>
					<td className="width-25">{data.year}<span className="break">Gesamt</span></td>
					<td className="width-10">{data.ID_QES}</td>
					<td className="width-10">{data.QES}</td>
					<td className="width-10">{data.ADV}</td>
					<td className="width-10">{data.BAS}</td>
					<td className="width-10">{data.Q_SEAL}</td>
					<td className="width-10">{data.QTSP}</td>
					<td className="width-15">{data.totalPoints}</td>
				</tr>
				{this.state.isOpen && data.months.map( (month,index) =>
					<StatisticsMonth key={data.year + "-" + index} year={data.year} month={month.month} data={month} type={type} monthOpen={monthOpen} onMonthOpen={onMonthOpen} />
				)}
			</>
		);
	}
};

class StatisticsTable extends Component
{
	constructor(props) {
		super(props);

		this.state = {
			statistics: undefined,
			type: 'day',
			monthOpen: undefined,
		};
	}

	handleChangeDetails (e) {
		this.setState({ type: e.target.value, monthOpen: undefined });
	}

	handleMonthOpen (o) {
		this.setState({ monthOpen: o });
	}

	render()
	{
		const statistics = this.props.statistics;

		return (
			<table className="stats-table table table-bordered">
				<thead className="stats-table-head">
					<tr>
						<th className="width-25 actions-total-head">
							<p onChange={e => this.handleChangeDetails (e)}>
								<label className="radiocontainer"> Details pro Tag
									<input type="radio" name="stats-type-id" className="stats-type-id" value="day" defaultChecked />
									<span className="checkmark"></span>
								</label>
								<label className="radiocontainer"> Details pro User
									<input type="radio" name="stats-type-id" className="stats-type-id" value="user" />
									<span className="checkmark"></span>
								</label>
							</p>
						</th>
						<th className="width-10 idadv-total-head">ID-QES</th>
						<th className="width-10 qes-total-head">QES</th>
						<th className="width-10 adv-total-head">ADV </th>
						<th className="width-10 bas-total-head">BAS</th>
						<th className="width-10 qseal-total-head">Q-SEAL</th>
						<th className="width-10 qtsp-total-head">Q-TSP</th>
						<th className="width-15 used-points-head">Verbrauchte Punkte</th>
					</tr>
				</thead>

				<tbody className="stats-data-output table-striped">
					{statistics && statistics.years && statistics.years.map( (year) =>
						<StatisticsYear key={'stat'+year.year} data={year} type={this.state.type} monthOpen={this.state.monthOpen} onMonthOpen={o => this.handleMonthOpen(o)} />
					)}
				</tbody>
			</table>
	);
}
}

StatisticsTable.propTypes = {
	statistics: PropTypes.object,
};

export default StatisticsTable;

