import React, { Component } from 'react';
import Header from './common/header/header.component.jsx';
import Footer from './common/footer/footer.component.jsx';
import DigiSeal from './common/digiSeal.component.jsx';
import AppConstant from "../constants/app";

class AppAuth extends Component {

    constructor(props) {
        super(props);
        setTimeout(() => { this.updateNavi(); }, 500);
    }

    updateNavi() {
        var location = window.location.href;

        $('#menu_content_small, #menu_content').find('.active').removeClass('active');
        $('#mainmenu-account').find('.active').removeClass('active');
        switch(true){
            case location.indexOf('account#/digiseal-points') !== -1:
                $('#menu_content_small').find('.contingent-overview').addClass('active');
                $('#menu_content').find('.contingent-overview').addClass('active');
                break;

            case location.indexOf('account#/account') !== -1:
                $('#menu_content_small').find('.myaccount-overview').addClass('active');
                $('#menu_content').find('.myaccount-overview').addClass('active');
                break;

            case location.indexOf('account#/point-access-key') !== -1:
                $('#menu_content_small').find('.transactionaccounts-overview').addClass('active');
                $('#menu_content').find('.transactionaccounts-overview').addClass('active');
                break;

            case location.indexOf('account#/stats-and-usage') !== -1:
                $('#menu_content_small').find('.statsandusage-overview').addClass('active');
                $('#menu_content').find('.statsandusage-overview').addClass('active');
                break;

            case location.indexOf('account#/email-notices') !== -1:
                $('#menu_content_small').find('.emailnotices-overview').addClass('active');
                $('#menu_content').find('.emailnotices-overview').addClass('active');
                break;
        }
    }

    componentDidMount(prevProps, prevState, snapshot)
    {
        $('#menu_content_small, #menu_content').find('li a').delay(5).click(() => {
            setTimeout(() => {
                this.updateNavi();
            }, 200);
        })

        $('#rightSubMenu .titleClick').off('click').on('click', function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
                $('#rightSubMenu').css('right', '-454px');
            } else {
                $(this).addClass('open');
                $('#rightSubMenu').css('right', '0');
            }
        })
    }

    render(){
        return (
            <div className="ds-wrapper">
                <Header type='auth' />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="mainmenu-small">
                                    <ul id="menu_content_small">
                                        <li className="myaccount-overview">
                                            <a href={`${AppConstant.ACCOUNT_ROOT_URL}account`}>
                                                Mein <DigiSeal /> Konto
                                            </a>
                                        </li>
                                        <li className="contingent-overview">
                                            <a href={`${AppConstant.ACCOUNT_ROOT_URL}digiseal-points`}>
                                                <DigiSeal /> Punkte
                                            </a>
                                        </li>
                                        <li className="transactionaccounts-overview">
                                            <a href={`${AppConstant.ACCOUNT_ROOT_URL}point-access-key`}>
                                                Punkte-Zugangsschlüssel
                                            </a>
                                        </li>
                                        <li className="statsandusage-overview">
                                            <a href={`${AppConstant.ACCOUNT_ROOT_URL}stats-and-usage`}>
                                               Statistik Punkte-Verbrauch
                                            </a>
                                        </li>
                                        <li className="emailnotices-overview">
                                            <a href={`${AppConstant.ACCOUNT_ROOT_URL}email-notices`}>
                                                E-Mail-Meldungen
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div id="rightSubMenu">
                                <span className="titleClick"><img src="./images/icons/arrow.png" /> Punkteverbrauch pro Aktion</span>
                                <table className="points-costs-overview">
                                    <thead>
                                        <tr>
                                            <th>Aktion Abkürzung</th>
                                            <th>Beschreibung</th>
                                            <th>Punkte pro Aktion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="id-adv-details">
                                            <td>ID-QES</td>
                                            <td>Identifikation für qualifiziertes sign-me-Signaturzertifikat</td>
                                            <td>1300</td>
                                        </tr>
                                        <tr className="qes-details">
                                            <td>QES</td>
                                            <td>Erzeugung einer qualifizierten sign-me-Signatur</td>
                                            <td>89</td>
                                        </tr>
                                        <tr className="adv-details">
                                            <td>ADV</td>
                                            <td>Erzeugung einer fortgeschrittenen sign-me-Signatur</td>
                                            <td>26</td>
                                        </tr>
                                        <tr className="bas-details">
                                            <td>BAS</td>
                                            <td>Erzeugung einer einfachen sign-me-Signatur</td>
                                            <td>15</td>
                                        </tr>
                                        <tr className="qseal-details">
                                            <td>Q-SEAL</td>
                                            <td>Erzeugung eines qualifizierten seal-me-Siegels</td>
                                            <td>100</td>
                                        </tr>
                                        <tr className="qtsp-details">
                                            <td>Q-TSP</td>
                                            <td>Erzeugung eines qualifizierten D-Trust-Zeitstempels</td>
                                            <td>12</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-md-9">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default AppAuth